h3 {
    font-family: 'Gill Sans';

}

.all-legos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@font-face {
    font-family: 'KBSticktoIt';
    src: url('./fonts/KBSticktoIt.woff2') format('woff2'),
    url('./fonts/KBSticktoIt.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


#headline {
    font-size: 4em;
    font-family: 'KBSticktoIt', cursive;
    padding: 6px;
    color: black;
    text-shadow:
    -2px -2px 0 #ffcf00,
    -2px 2px 0 #ffcf00,
    2px 2px 0 #ffcf00,
    2px -2px 0 #ffcf00;
    text-align: center;
}

.lego h3 {
    text-align: center;
}

.lego {
    text-align: center;
    margin-bottom: 8px;
}

#container {
    position: relative;
}

#cross-through {
    text-decoration: line-through;
}

#toolbar {
    display: flex;
    background-color: #ffcf00;
    padding: 10px;
    outline: 2px solid black;
    justify-content: space-between;
}

#filters, #my_legos {
    display: flex;
    align-items: center;
}

#filters label {
    padding-left: 10px;
    padding-right: 10px;
}

#owned-checkbox {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

#lego-closeup img {
    width: 100%;
}

#lego-closeup {
    text-align: center;
}

#in_collection {
    font-size: 14px;
    display: flex;
    justify-content: center;
}

#plus {
    text-align: left;
    font-size: 30px;
    margin: 0px;
    color: #ffcf00;
    font-style: bold;
    text-shadow: 1px 1px #000000;
    display: inline;
    position: absolute;
}

#remove {
    margin-left: 3px;
    font-size: 7px;
    padding: 4px;

}

#search_box {
    margin-left: 4px;
    margin-right:4px;
}

#add {
    border: 1px solid black;
}
