h3 {
    font-family: 'Gill Sans';

}


@font-face {
    font-family: 'KBSticktoIt';
    src: url('./fonts/KBSticktoIt.woff2') format('woff2'),
    url('./fonts/KBSticktoIt.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}



.lego-pic {
    width: 70%;
}

.lego h3 {
    text-align: center;
}

.lego {
    text-align: center;
}
